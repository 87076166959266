<template>
	<v-dialog v-model="dialog" fullscreen>
		<v-card fill-height full-width>
			<v-list>
				<v-list-tile>
					<v-layout row justify-center align-center>
						<v-btn icon flat @click="goTo('home')">
							<v-icon color="primary" x-large>home</v-icon>
						</v-btn>
						<v-spacer />
						<v-btn icon flat @click="closeMe()">
							<v-icon color="primary" x-large>close</v-icon>
						</v-btn>
					</v-layout>
				</v-list-tile>

				<v-list-tile v-if="isSuperAdmin" avatar @click="goTo('sa-home')">
					<v-list-tile-avatar>
						<v-icon color="red">security</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title v-t="'super_admin.navbar_button_tooltip'" />
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile v-if="accountingFirms && accountingFirms.length <= 1" prepend-icon="business_center">
					<v-list-tile-avatar>
						<v-icon color="primary">business_center</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title v-text="selectedAccountingFirm ? selectedAccountingFirm.name : ''" />
					</v-list-tile-content>
				</v-list-tile>
				<v-list-group v-else>
					<template v-slot:activator>
						<v-list-tile avatar>
							<v-list-tile-avatar>
								<v-icon color="primary">business_center</v-icon>
							</v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title>{{ $tc('home.accounting_firm', accountingFirms ? accountingFirms.length : 0) }}</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</template>
					<v-list-tile v-for="accountingFirm in accountingFirms" :key="accountingFirm.id" avatar @click="selectAccountingFirm(accountingFirm)">
						<v-list-tile-avatar>
							<v-img contain :src="accountingFirm.logo" />
						</v-list-tile-avatar>
						<v-list-tile-content>
							<v-list-tile-title v-text="accountingFirm.name" />
						</v-list-tile-content>
					</v-list-tile>
				</v-list-group>

				<v-list-tile v-if="companies && companies.length <= 1" avatar>
					<v-list-tile-avatar>
						<v-icon color="primary">business</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title v-text="selectedCompany ? selectedCompany.company : ''" />
					</v-list-tile-content>
				</v-list-tile>
				<v-list-group v-else>
					<template v-slot:activator>
						<v-list-tile avatar>
							<v-list-tile-avatar>
								<v-icon color="primary">business</v-icon>
							</v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title>{{ $tc('home.company', companies ? companies.length : 0) }}</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</template>
					<v-list-tile v-for="company in companies" :key="company.id" @click="selectCompany(company)">
						<v-list-tile-content>
							<v-list-tile-title v-text="company.company" />
						</v-list-tile-content>
					</v-list-tile>
				</v-list-group>

				<v-list-tile v-for="module in modules" :key="module.id" avatar @click="goTo(module.name)">
					<v-list-tile-avatar>
						<v-icon color="primary">{{ module.icon }}</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title v-text="module.title" />
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile avatar @click="goTo(isAccountant ? 'accounting-firm-settings' : 'company-settings')">
					<v-list-tile-avatar>
						<v-icon color="primary">settings</v-icon>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title v-t="'settings.name'" />
					</v-list-tile-content>
				</v-list-tile>

				<v-list-tile v-if="appService.hasModule('support')" avatar @click.stop="onHelpClick()">
					<v-list-tile-avatar>
						<SupportChatButton toolbar />
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title v-t="'support'" />
					</v-list-tile-content>
				</v-list-tile>

				<UserMenu minimized />
			</v-list>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'NavBarSmartphone',
	components: {
		UserMenu: () => ({
			component: import('@/components/Navigation/UserMenu')
		}),
		SupportChatButton: () => ({
			component: import('@/components/Support/SupportChatButton')
		})
	},
	mixins: [AppModuleGuard],
	props: {
		modules: {
			required: true,
			type: Array
		},
		navigationCounter: {
			required: true,
			type: Number
		}
	},
	data: function () {
		return {
			loading: true,
			dialog: true
		}
	},
	computed: {
		...mapState({
			logo: state => state.whitelabel.selected.logo,
			isSuperAdmin: state => state.auth.isSuperAdmin,
			isAccountant: state => state.auth.isAccountant,
			accountingFirms: state => state.accountingFirm.list,
			selectedAccountingFirm: state => state.accountingFirm.selected,
			companies: state => state.company.list,
			selectedCompany: state => state.company.selected,
			userTitle: state => state.user.title,
			userFirstname: state => state.user.firstname,
			userLastname: state => state.user.lastname,
			token: state => state.auth.token
		}),
		showBackButton: function () {
			return this.navigationCounter > 1
		}
	},
	watch: {
		'$route.name': {
			handler: function (newVal, oldVal) {
				if (oldVal && oldVal !== newVal) {
					this.closeMe()
				}
			},
			immediate: true
		}
	},
	methods: {
		goBack: function () {
			this.appEventBus.emit(this.appEvents.GO_BACK)
		},
		goTo: function (routePath) {
			this.appService.goTo(routePath)
		},
		closeMe: function () {
			this.appEventBus.emit(this.appEvents.HIDE_NAVSIDEBAR)
		},
		logout: function () {
			this.appEventBus.emit(this.appEvents.LOGOUT)
			this.closeMe()
		},
		selectAccountingFirm: function (accountingFirm) {
			if (this.selectedAccountingFirm.domain === accountingFirm.domain) {
				this.appService.selectAccountingFirm(accountingFirm.id)
			} else {
				localStorage.removeItem('lastSelectedAccountingFirmId')
				this.appService.redirectToAccountingFirmWorkspace(accountingFirm)
			}
			this.closeMe()
		},
		selectCompany: function (vendor) {
			this.appService.selectVendor(vendor.id)
			this.closeMe()
		},
		onHelpClick: function () {
			this.appEventBus.emit(this.appEvents.TOGGLE_SUPPORT_CHAT)
			this.appEventBus.emit(this.appEvents.HIDE_NAVSIDEBAR)
		}
	}
}
</script>
